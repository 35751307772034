/**
 * The contact page
 *
 */

// IMPORTS
import React from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import moment from "moment";
import {
  SEO,
  GlobalStyle,
  NavigationHeader,
  ConsentBanner,
  ContactFooter,
  ContactCardPerson,
  // Grid
} from "../components";

import {AppState} from "../store/";
import {
  updateRegionAction,
  setTimezoneDetectedAction,
  updateUTCOffsetAction,
  updateIsNavigationInvertedAction,
  updateIsNavigationHiddenAction,
  updateIsHomepageAboveVideoAction,
} from "../store/system/actions";
import {DflRegionType, WPAcfPostsProps, FullpageHeadline, stage, px, breakpoints, MainContentWrapper} from "../models";
import {getDflRegionByTimezone, encryptMail} from "../util/util";

import "../../static/fonts.css";

// COMPONENT PROPERTIES
interface PropsFromState {
  timezoneDetected: boolean;
  selectedRegion: DflRegionType;
}
const mapStateToProps = (state: AppState): PropsFromState => {
  return {
    timezoneDetected: state.system.timezoneDetected,
    selectedRegion: state.system.selectedRegion,
  };
};

interface PropsFromDispatch {
  updateRegion: (region: DflRegionType) => void;
  setTimezoneDetected: () => void;
  updateUTCOffset: (newOffset: number) => void;
  updateNavigationInverted: (isInverted: boolean) => void;
  updateIsNavigationHidden: (isNavigationHidden: boolean) => void;
  updateIsHomepageAboveVideo: (isInverted: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateRegion: (region: DflRegionType) => {
      return dispatch(updateRegionAction(region));
    },
    setTimezoneDetected: () => {
      return dispatch(setTimezoneDetectedAction());
    },
    updateUTCOffset: (newOffset: number) => {
      return dispatch(updateUTCOffsetAction(newOffset));
    },
    updateNavigationInverted: (isInverted: boolean) => {
      return dispatch(updateIsNavigationInvertedAction(isInverted));
    },
    updateIsNavigationHidden: (isNavigationHidden: boolean) => {
      return dispatch(updateIsNavigationHiddenAction(isNavigationHidden));
    },
    updateIsHomepageAboveVideo: (isInverted: boolean) => {
      return dispatch(updateIsHomepageAboveVideoAction(isInverted));
    },
  };
};

interface IndexPageProps extends PropsFromState, PropsFromDispatch {
  data: {
    wpPost: WPAcfPostsProps;
  };
}

// STYLES
const IntroHeadlineWrapper = styled.div`
  margin-top: 100px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    margin-top: 120px;
  }

  /* desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    margin-top: 140px;
  }
`;
const ContactSectionWrapper = styled.div`
  width: 100%;
  padding: 32px 0;
  margin-bottom: 50px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    padding: 40px 0;
    margin-bottom: 100px;
  }
`;
const ContactSection = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  /* desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.outerWidth)};
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const ContactWrapper = styled.div`
  margin-bottom: 50px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    margin: 0 auto 30px;
  }

  /* desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    margin: 0 0 10px 0;
  }
`;

// COMPONENT
class IndexPage extends React.Component<IndexPageProps> {
  constructor(props: IndexPageProps) {
    super(props);
  }

  componentDidMount() {
    // get Timezone and utc offset once
    if (!this.props.timezoneDetected) {
      const dflRegion = getDflRegionByTimezone();
      this.props.updateRegion(dflRegion);

      if (moment && moment().utcOffset() !== null) {
        // ignore daylight savings time
        const isDayLightSavings = moment().isDST();
        const dayLightSavingsOffset = isDayLightSavings ? 1 : 0;
        this.props.updateUTCOffset(moment().utcOffset() / 60 - dayLightSavingsOffset);
      }
      this.props.setTimezoneDetected();
    }

    // set navigation to black (could be inverted, coming from landing page)
    this.props.updateNavigationInverted(false);
    this.props.updateIsHomepageAboveVideo(false);

    // unhide the menu if it is hidden
    this.props.updateIsNavigationHidden(false);
  }

  render() {
    const {data, selectedRegion} = this.props;
    const {contactsBli: contactsBLI, contactsDfl: contactsDFL} = data.wpPost.contacts;
    const localBliContacts = contactsBLI.filter(contact => {
      return contact.market === selectedRegion;
    });
    const bliContactsExceptLocal = contactsBLI.filter(contact => contact.market !== this.props.selectedRegion);

    return (
      <div>
        <GlobalStyle />
        <ConsentBanner />
        <SEO title="Contact" />
        {/* @ts-ignore */}
        <NavigationHeader isTextDark />

        <MainContentWrapper>
          <IntroHeadlineWrapper>
            <FullpageHeadline>Local Contacts</FullpageHeadline>
          </IntroHeadlineWrapper>

          <ContactSectionWrapper>
            <ContactSection>
              {localBliContacts.map((contact, contactIndex) => (
                <ContactWrapper key={contactIndex}>
                  <ContactCardPerson
                    avatar={contact.image.sourceUrl}
                    {...contact}
                    email={encryptMail(contact.email)}
                  />
                </ContactWrapper>
              ))}
            </ContactSection>
          </ContactSectionWrapper>

          <FullpageHeadline>Bundesliga International GmbH</FullpageHeadline>
          <ContactSectionWrapper>
            <ContactSection>
              {bliContactsExceptLocal.map((contact, contactIndex) => (
                <ContactWrapper key={contactIndex}>
                  <ContactCardPerson
                    avatar={contact.image.sourceUrl}
                    {...contact}
                    email={encryptMail(contact.email)}
                  />
                </ContactWrapper>
              ))}
            </ContactSection>
          </ContactSectionWrapper>

          <FullpageHeadline>DFL Deutsche Fußball Liga GmbH</FullpageHeadline>
          <ContactSectionWrapper>
            <ContactSection>
              {contactsDFL.map((contact, contactIndex) => (
                <ContactWrapper key={contactIndex}>
                  <ContactCardPerson
                    avatar={contact.image.sourceUrl}
                    {...contact}
                    email={encryptMail(contact.email)}
                  />
                </ContactWrapper>
              ))}
            </ContactSection>
          </ContactSectionWrapper>
          <ContactFooter />
        </MainContentWrapper>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query($contacts_id: Int = 1) {
    wpPost(databaseId: {eq: $contacts_id}) {
      contacts {
        contactsBli {
          image {
            sourceUrl
          }
          name
          market
          position
          positionTwo
          email
          phone
        }
        contactsDfl {
          image {
            sourceUrl
          }
          name
          market
          position
          positionTwo
          phone
          email
        }
      }
    }
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
